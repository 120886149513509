export default {
    emailManagerState: {
        attachments: null,
        allAccounts: null,
        emailResponse: null,
        accountEmail: null,
        unassignedEmailCount: null,
    },
    emailTemplates: [
        // aviva templates
        {
            id: "blankTemplate",
            name: "Blank Template",
            type: "aviva"
        },
        {
            id: "updateLOATemplate",
            name: "Update LOA Template",
            type: "aviva"
        },
        // {
        //     id: "pensionReportEmailQA",
        //     name: "Pension Report Email QA",
        //     required: ["lor", "ar"]
        // },
        {
            id: "clairesRules",
            name: "Claires Rules",
            type: "aviva"
        },
        {
            id: "applicationReceivedSuccessfully",
            name: "SP991271 - Application received successfully",
            type: "aviva"
        },
        {
            id: "definedBenefits",
            name: "SP991284 - Defined Benefits",
            required: ["lor"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderUnknownPolicyWetPostFollowUp",
            name:
                "SP991341 - LOA Covering Email Data Request Provider Unknown Policy Wet Post Follow Up",
            required: ["loa_ukp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderChaserUnknownPolicyNumber",
            name:
                "SP991313 - LOA Covering Email Data Request To Provider Chaser Unknown Policy Number",
            required: ["loa_ukp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderKnownPolicyNumber",
            name:
                "SP991312 - LOA Covering Email Data Request Provider Known Policy Number",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderWetSignature",
            name:
                "SP991314 - LOA Covering Email Data Request Provider Wet Signature",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestToProvider",
            name: "SP991285 - LOA Covering Email Data Request To Provider",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestToProviderUnknown",
            name: "SP991286 - LOA Covering Email Data Request To Provider Unknown",
            required: ["loa_ukp"],
            type: "aviva"
        },
        {
            id: "loaRejectedContactProvider",
            name: "SP991307 - LOA Rejected Contact Provider",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaRejectedNoPolicyNumber",
            name: "SP991308 - LOA Rejected No Policy Number",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaSent",
            name: "SP991304 - LOA Sent",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaSentUpdatedInfo",
            name: "SP991309 - LOA Sent Updated Info",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaWetSignatureNeeded",
            name: "SP991294 - LOA Wet Signature Needed",
            required: ["loa_kp", "loa_kp_ns", "loa_ukp_ns"],
            type: "aviva"
        },
        {
            id: "missingInformation",
            name: "SP991272 - Missing Information",
            type: "aviva"
        },
        {
            id: "missingInformationChaser",
            name: "SP991273 - Missing Information Chaser",
            type: "aviva"
        },
        {
            id: "missingInformationChaserClosed",
            name: "SP991274 - Missing Information Chaser Closed",
            type: "aviva"
        },
        {
            id: "missingInformationReceived",
            name: "SP991275 - Missing Information Received",
            type: "aviva"
        },
        {
            id: "missingInformationStillRequiredClosed",
            name: "SP991277 - Missing Information Still Required Closed",
            type: "aviva"
        },
        {
            id: "missingInformationStillRequiredContinued",
            name: "SP991276 - Missing Information Still Required Continued",
            type: "aviva"
        },
        {
            id: "noResponseFromProvider",
            name: "SP991315 - No Response From Provider",
            type: "aviva"
        },
        {
            id: "noResponseTwoMonths",
            name: "SP991318 - No Response Two Months",
            type: "aviva"
        },
        {
            id: "providerUnableToFindPolicy",
            name: "SP991321 - Provider Unable To Find Policy",
            type: "aviva"
        },
        {
            id: "pensionReportEmail",
            name: "SP991323 - Pension Report Email",
            required: ["lor", "ar"],
            type: "aviva"
        },
        {
            id: "providerConfirmedRetirementIncomeOrFullCash",
            name: "SP991322 - Provider Confirmed Retirement Income Or Full Cash",
            type: "aviva"
        },
        {
            id: "providerConfirmedTransferOut",
            name: "SP991342 - Provider Confirmed Transfer Out",
            type: "aviva"
        },
        {
            id: "providerLORSentDirectToCustomer",
            name: "SP991319 - Provider LOR Sent Direct To Customer",
            type: "aviva"
        },
        {
            id: "providerLORSentToCustomerClosureAfterNoResponse",
            name:
                "SP991320 - Provider LOR Sent To Customer Closure After No Response",
            type: "aviva"
        },
        {
            id: "serviceCompleteSuccessful",
            name: "SP991324 - Service Complete Successful",
            type: "aviva"
        },
        {
            id: "serviceCompleteUnsuccessful",
            name: "SP991344 - Service Complete Unsuccessful",
            type: "aviva"
        },
        {
            id: "searchUnsuccessful",
            name: "SP991278 - Search Unsuccessful",
            type: "aviva"
        },
        {
            id: "signedLOAChaser",
            name: "SP991335 - Signed LOA Chaser",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "signedLOAChaserPrintedVersion",
            name: "SP991343 - Signed LOA Chaser Printed Version",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "signatureWordingUpdate",
            name: "SP991432 - Signature Missing from Application",
            type: "aviva"
        },
        {
            id: "signatureMissingFromApplicationChaser",
            name: "SP991433 - Signature Missing from Application Chaser ",
            type: "aviva"
        },
        {
            id: 'claireRulesResponse',
            name: 'Template 02 - Response for Claire’s rules',
            type: "aviva"
        },
        {
            id: 'eRNoCompanyPensionDuringClientEmployment',
            name: 'Template 16 - Email where ER had NO company pension during clients employment:',
            type: "aviva"
        },
        {
            id: 'howToTransferPensionToAviva',
            name: 'Template 17 - How to transfer your pension to Aviva (only to be used when client has specifically asked us about transferring)',
            type: "aviva"
        },
        {
            id: 'moreInfoFromEmployer',
            name: 'Template 15a - more information from employer',
            type: "aviva"
        },
        {
            id: 'moreInfoFromEmployerChaser',
            name: 'Template 15b - more information from employer chaser (info pulled from 15a)',
            type: "aviva"
        },
        {
            id: 'refundOfContributions',
            name: 'Template 14 - Email for refund of contributions',
            type: "aviva"
        },
        {
            id: 'unknownDBBlankEmail',
            name: 'Template 07 - Unknown DB - Blank email (this needs to pull through employer, OP details)',
            type: "aviva"
        },
        {
            id: 'template18_automatedLorUploadEmail',
            name: 'Template 18 - Automated Lor Upload Email',
            type: "aviva"
        },
        {
            id: 'template19_crystallisedPension',
            name: 'Template 19 - Crystallised Pension',
            type: "aviva"
        },
        {
            id: 'template20_pcsLORTemplate',
            name: 'CD10316 - PCS Unisure LOR Email Template',
            type: "aviva"
        },
        {
            id: 'oisPlatform',
            name: 'CD10327 - PCS OIS LOR Email Template',
            type: "aviva"
        },
        {
            id: "providerNotMatched",
            name: "Template 21 - Provider Not Matched",
            type: "aviva"
        },
        {
            id: "providerNotMatchedChaser",
            name: "Template 22 - Provider Not Matched Chaser",
            type: "aviva"
        },

        // wealthify
        {
            id: "loaCoveringEmailDataRequestToProviderWfy",
            name: "SP991285 - LOA Covering Email Data Request To Provider",
            type: "wealthify"
        },{
            id: "loaCoveringEmailDataRequestToProviderUnknownWfy",
            name: "SP991286 - LOA Covering Email Data Request To Provider Unknown",
            type: "wealthify"
        },{
            id: "loaCoveringEmailDataRequestProviderWetSignatureWfy",
            name: "SP991314 - LOA Covering Email Data Request Provider Wet Signature",
            type: "wealthify"
        },{
            id: "loaCoveringEmailDataRequestProviderKnownPolicyNumberWfy",
            name: "SP991312 - LOA Covering Email Data Request Provider Known Policy Number",
            type: "wealthify"
        },{
            id: "loaCoveringEmailDataRequestProviderChaserUnknownPolicyNumberWfy",
            name: "SP991313 - LOA Covering Email Data Request To Provider Chaser Unknown Policy Number",
            type: "wealthify"
        },{
            id: "template18_automatedLorUploadEmailWfy",
            name: "Template 18 - Automated Lor Upload Email",
            type: "wealthify"
        },{
            id: "template20_pcsLORTemplateWfy",
            name: "CD10316 - PCS Unisure LOR Email Template",
            type: "wealthify"
        },
    ],
    emailTemplatesWithLetters: [
        {
            id: "blankTemplate",
            name: "Blank Template",
            type: "aviva"
        },
        {
            id: "clairesRules",
            name: "Claires Rules",
            type: "aviva"
        },
        {
            id: "applicationReceivedSuccessfully",
            name: "SP991271 - Application received successfully",
            type: "aviva"
        },
        {
            id: "definedBenefits",
            name: "SP991284 - Defined Benefits",
            required: ["lor"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderUnknownPolicyWetPostFollowUp",
            name:
                "SP991341 - LOA Covering Email Data Request Provider Unknown Policy Wet Post Follow Up",
            required: ["loa_ukp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderChaserUnknownPolicyNumber",
            name:
                "SP991313 - LOA Covering Email Data Request To Provider Chaser Unknown Policy Number",
            required: ["loa_ukp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderKnownPolicyNumber",
            name:
                "SP991312 - LOA Covering Email Data Request Provider Known Policy Number",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestProviderWetSignature",
            name:
                "SP991314 - LOA Covering Email Data Request Provider Wet Signature",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestToProvider",
            name: "SP991285 - LOA Covering Email Data Request To Provider",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaCoveringEmailDataRequestToProviderUnknown",
            name: "SP991286 - LOA Covering Email Data Request To Provider Unknown",
            required: ["loa_ukp"],
            type: "aviva"
        },
        {
            id: "loaRejectedAvivaResolve",
            name: "SP991305 - LOA Rejected Aviva Resolve",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaRejectedContactProvider",
            name: "SP991307 - LOA Rejected Contact Provider",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaRejectedNoPolicyNumber",
            name: "SP991308 - LOA Rejected No Policy Number",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaSent",
            name: "SP991304 - LOA Sent",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaSentUpdatedInfo",
            name: "SP991309 - LOA Sent Updated Info",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "loaWetSignatureNeeded",
            name: "SP991294 - LOA Wet Signature Needed",
            required: ["loa_kp", "loa_kp_ns", "loa_ukp_ns"],
            type: "aviva"
        },
        {
            id: "loaWetSignatureNeededNotProvidedInApp",
            name: "SP991295 - LOA Wet Signature Needed Not Provided In App",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "missingInformation",
            name: "SP991272 - Missing Information",
            type: "aviva"
        },
        {
            id: "missingInformationChaser",
            name: "SP991273 - Missing Information Chaser",
            type: "aviva"
        },
        {
            id: "missingInformationChaserClosed",
            name: "SP991274 - Missing Information Chaser Closed",
            type: "aviva"
        },
        {
            id: "missingInformationReceived",
            name: "SP991275 - Missing Information Received",
            type: "aviva"
        },
        {
            id: "missingInformationStillRequiredClosed",
            name: "SP991277 - Missing Information Still Required Closed",
            type: "aviva"
        },
        {
            id: "missingInformationStillRequiredContinued",
            name: "SP991276 - Missing Information Still Required Continued",
            type: "aviva"
        },
        {
            id: "noResponseFromProvider",
            name: "SP991315 - No Response From Provider",
            type: "aviva"
        },
        {
            id: "noResponseTwoMonths",
            name: "SP991318 - No Response Two Months",
            type: "aviva"
        },
        {
            id: "providerUnableToFindPolicy",
            name: "SP991321 - Provider Unable To Find Policy",
            type: "aviva"
        },
        {
            id: "pensionReportEmail",
            name: "SP991323 - Pension Report Email",
            required: ["lor", "ar"],
            type: "aviva"
        },
        {
            id: "providerConfirmedRetirementIncomeOrFullCash",
            name: "SP991322 - Provider Confirmed Retirement Income Or Full Cash",
            type: "aviva"
        },
        {
            id: "providerConfirmedTransferOut",
            name: "SP991342 - Provider Confirmed Transfer Out",
            type: "aviva"
        },
        {
            id: "providerLORSentDirectToCustomer",
            name: "SP991319 - Provider LOR Sent Direct To Customer",
            type: "aviva"
        },
        {
            id: "providerLORSentToCustomerClosureAfterNoResponse",
            name:
                "SP991320 - Provider LOR Sent To Customer Closure After No Response",
            type: "aviva"
        },
        {
            id: "serviceCompleteSuccessful",
            name: "SP991324 - Service Complete Successful",
            type: "aviva"
        },
        {
            id: "serviceCompleteUnsuccessful",
            name: "SP991344 - Service Complete Unsuccessful",
            type: "aviva"
        },
        {
            id: "searchUnsuccessful",
            name: "SP991278 - Search Unsuccessful",
            type: "aviva"
        },
        {
            id: "signedLOAChaser",
            name: "SP991335 - Signed LOA Chaser",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "signedLOAChaserPrintedVersion",
            name: "SP991343 - Signed LOA Chaser Printed Version",
            required: ["loa_kp"],
            type: "aviva"
        },
        {
            id: "signatureWordingUpdate",
            name: "SP991432 - Signature Missing from Application",
            type: "aviva"
        },
        {
            id: "signatureMissingFromApplicationChaser",
            name: "SP991433 - Signature Missing from Application Chaser ",
            type: "aviva"
        },
        {
            id: 'claireRulesResponse',
            name: 'Template 02 - Response for Claire’s rules',
            type: "aviva"
        },
        {
            id: 'eRNoCompanyPensionDuringClientEmployment',
            name: 'Template 16 - Email where ER had NO company pension during clients employment:',
            type: "aviva"
        },
        {
            id: 'howToTransferPensionToAviva',
            name: 'Template 17 - How to transfer your pension to Aviva (only to be used when client has specifically asked us about transferring)',
            type: "aviva"
        },
        {
            id: 'moreInfoFromEmployer',
            name: 'Template 15a - more information from employer',
            type: "aviva"
        },
        {
            id: 'moreInfoFromEmployerChaser',
            name: 'Template 15b - more information from employer chaser (info pulled from 15a)',
            type: "aviva"
        },
        {
            id: 'refundOfContributions',
            name: 'Template 14 - Email for refund of contributions',
            type: "aviva"
        },
        {
            id: 'unknownDBBlankEmail',
            name: 'Template 07 - Unknown DB - Blank email (this needs to pull through employer, OP details)',
            type: "aviva"
        },
        {
            id: 'template18_automatedLorUploadEmail',
            name: 'Template 18 - Automated Lor Upload Email',
            type: "aviva"
        },
        {
            id: 'template19_crystallisedPension',
            name: 'Template 19 - Crystallised Pension',
            type: "aviva"
        },
        {
            id: 'template20_pcsLORTemplate',
            name: 'CD10316 - PCS Unisure LOR Email Template',
            type: "aviva"
        },
        {
            id: 'oisPlatform',
            name: 'CD10327 - PCS OIS LOR Email Template',
            type: "aviva"
        },
        {
            id: "providerNotMatched",
            name: "Template 21 - Provider Not Matched",
            type: "aviva"
        },
        {
            id: "providerNotMatchedChaser",
            name: "Template 22 - Provider Not Matched Chaser",
            type: "aviva"
        },
        {
            id: "letter-9",
            name: "Letter 9 - Information request & LOA - Known",
            type: "aviva"
        }, {
            id: "letter-9a",
            name: "Letter 9a - Information request & LOA - Unknown",
            type: "aviva"
        }, {
            id: "letter-10c",
            name: "Letter 10c - Cover Letter & Blank LOA for member to sign",
            type: "aviva"
        }, {
            id: "letter-11",
            name: "Letter 11 - Information request - Chaser for provider",
            type: "aviva"
        },{
            id: "letter-wet-signature",
            name: "Information request - Wet Signature",
            type: "aviva"
        },
    ]
};
